<template>
  <ContactViewGreetings v-if="contactView.length === 0"></ContactViewGreetings>
  <div v-else>
    <h5>{{ $t("contactView.listTitle") }}</h5>
    <p>{{ $t("contactView.listDescription") }}</p>
    <hr />
    <router-link
      v-for="(item, i) in contactView"
      :key="item.id"
      :to="`/my-academy/content/ContactView/${item.id}`"
    >
      <div class="row align-items-center my-2 border p-3 hover">
        <div class="col-12 col-md-9">
          <div class="d-flex align-items-center">
            <h1 class="mr-3 text-primary">{{ i + 1 }}</h1>
            <div>
              <h5 class="text-primary">{{ item.title }}</h5>
              <p>{{ item.description }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <button class="btn btn-pill btn-warning mx-1">
            {{ $t("edit") }}
          </button>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import ActionMixin from "../action.mixin.js";
import ContactViewGreetings from "./ContactView/Greetings.vue";
export default {
  name: "ContactView",
  mixins: [ActionMixin],
  components: {
    ContactViewGreetings,
  },
  computed: {
    contactView() {
      return this.getSettings.contactView;
    },
  },
};
</script>

<style></style>
