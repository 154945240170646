<template>
  <div>
    <ContactView></ContactView>
  </div>
</template>

<script>
import ContactView from "../../components/MyAcademy/Content/ContactView.vue";
export default {
  components: {
    ContactView,
  },
};
</script>

<style></style>
