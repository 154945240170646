<template>
  <div class="text-center">
    <img src="/android-chrome-192x192.png" width="120" alt="" />
    <h4 class="mt-5">{{ $t("contactView.greeting") }}</h4>
    <p class="mt-4 my-4">{{ $t("contactView.greetingDescription") }}</p>

    <router-link to="/my-academy/content/ContactView/Create">
      <button class="btn btn-pill btn-info mx-1">
        {{ $t("contactView.buttonText") }}
      </button>
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
